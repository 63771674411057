@import 'styles/variables.less';

.critical-error {
  min-height: calc(var(--vh, 1vh) * 100);
  min-width: @breakpoint-mobile-sm;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: @padding-xl 0;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 @padding-lg;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: @margin-lg;
  }

  &__data {
    word-break: break-word;
    white-space: pre-wrap;
  }
}
