@import "styles/variables.less";

.room-broadcast-invite {
  padding: @padding-lg @padding-lg;
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: @padding-sm @padding-sm;
  }

  &__scroll-container {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: #d9d9d9 #a0a0a0;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      height: 20px;
      border-radius: 2px;
      background-color: #d9d9d9;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a0;
    }
  }

  &__text {
    text-align: center;
    padding: 0 12px;
    margin: 12px 0 24px;
    font-size: 14px;

    &_large {
      font-size: 16px;
    }
  }

  &__camera-box {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    padding-bottom: 56.25%;
    margin-bottom: 24px;
    background-color: #a0a0a0;
  }

  &__loader {
    padding: 8px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eee;
  }

  &__camera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
  }
}
