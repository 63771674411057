@import "styles/variables.less";

.dialog-video-view {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    z-index: 1;
    color: #ffffffd9;
    list-style: none;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: auto;
    line-height: 1;

    li {
      padding: 12px;
      margin-left: 12px;
      cursor: pointer;
      font-size: 20px;
    }
  }

  &__content {
    margin: auto;

    .react-player {
      max-height: 100vh;

      video {
        max-height: 100vh;
      }
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
