@import 'styles/variables.less';

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  scrollbar-color: #d9d9d9 #a0a0a0;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    height: 20px;
    border-radius: 1px;
    background-color: #d9d9d9;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
  }

  &__user {
    display: flex;
    padding: 6px;
    margin-bottom: 12px;
    border-radius: 5px;
    transition: background-color 0.15s ease-in-out;
    overflow: hidden;

    &:hover {
      background-color: @background-color-base;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    margin-bottom: 4px;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }

  &__tag {
    margin-left: 6px;
  }

  &__subtitle {
    line-height: 1;
    font-size: 14px;

    @media screen and (max-width: 425px) {
      font-size: 12px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    button:not(:last-child) {
      margin-right: 6px;
    }
  }

  &__context-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
