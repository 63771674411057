@import 'styles/variables.less';

.room-hands-dialog {
  padding: @padding-lg @padding-lg;
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: @padding-sm @padding-sm;
  }

  &__lower-hands {
    margin-bottom: 24px;
    height: 40px;
  }
}
