.empty-box {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  &__image {
    width: 100%;
    max-width: 420px;
    height: auto;
    object-fit: contain;
  }
}
