.dialog-file-selector-audio {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 50px;
  height: auto;
  padding: 6px;
  line-height: 20px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;

  &__cover {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    flex: 0 0 50px;
    z-index: 0;
    border-radius: 5px;
    overflow: hidden;
  }

  &__image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.55);
    z-index: 2;

    &_active {
      display: flex;
    }
  }

  &__bubble {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #1b8ce3;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      opacity: 0.8;
    }

    &-pause {
      background-image: url(../../../../../assets/images/controls/pause.svg);
    }

    &-play {
      background-image: url(../../../../../assets/images/controls/play.svg);
    }
  }

  &__data {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    width: 50%;
    min-width: 0;
    flex: 1 1 auto;
    line-height: 1;
  }

  &__name {
    flex-grow: 1;
    overflow: hidden;
    padding-right: 12px;
    text-overflow: ellipsis;
    width: 1px;
    white-space: nowrap;
  }

  &__duration {
    font-size: 14px;
    margin-right: 6px;
  }

  &:hover {
    border-color: #1b8ce3;

    .dialog-file-selector-audio__overlay {
      display: flex;
    }
  }
}
