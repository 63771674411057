.uploader-file {
  display: grid;
  grid-template-columns: 40px 5fr 4fr;
  grid-template-areas:
    'icon info bar'
    'error error error';
  align-items: center;
  padding: 6px;
  gap: 0 8px;
}

.uploader-file__info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.uploader-file__name {
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 0 6px;
  font-size: 14px;
}

.uploader-file__size {
  margin: 0;
  line-height: 1;
  font-size: 12px;
}

.upload-file__bar {
  grid-area: bar;
  width: 100%;
}

.upload-file__error {
  grid-area: error;
  justify-self: end;
  font-size: 12px;
  margin: 6px 16px 0 0;
  color: #ff4d4f;
}
