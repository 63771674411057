.silde-selector {
  position: absolute;
  bottom: 50px;
  top: 0;
  right: 0;
  width: 302px;
  height: calc(100% - 50px);
  background-color: white;
  cursor: pointer;
}
