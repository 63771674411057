@import 'styles/variables.less';

.dialog-file-selector-item {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  z-index: 0;

  &__name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    color: #fff;
    text-align: center;
    padding: 6px 12px;
    opacity: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    z-index: 2;
  }

  &__info {
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 24px;
    border-radius: 0 0 0 5px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    opacity: 1;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    font-size: 14px;
    min-width: 130px;
    z-index: 2;
  }

  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    z-index: 3;
  }
}

.dialog-file-selector-item:hover .dialog-file-selector-item__name,
.dialog-file-selector-item:hover .dialog-file-selector-item__info,
.hidden {
  opacity: 0;
}

.dialog-file-selector-item:hover .dialog-file-selector-item__overlay,
.visible {
  opacity: 1;
}
