@import 'styles/variables.less';

.dialog-file-selector {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  &__upload-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 0 24px;
    margin: 24px 0;

    @media screen and (max-width: 768px) {
      padding: 0 12px;
      margin: 12px 0;
    }
  }

  &__content {
    padding: 0 10px 0 24px;
    overflow: hidden;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
      padding: 0 4px 0 12px;
    }

    &-inner {
      overflow-y: scroll;
      display: grid;
      height: 100%;
      text-align: justify;
      grid-template-areas: 'a a a';
      grid-gap: 24px;
      grid-auto-rows: max-content;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        height: 28px;
        border-radius: 1px;
        background-color: #a0a0a0;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #d9d9d9;
      }

      @media screen and (max-width: 768px) {
        padding-right: 4px;
        grid-template-areas: 'a a';
        grid-gap: 12px;
      }

      @media screen and (max-width: 520px) {
        grid-template-areas: 'a';
      }

      &-audio {
        grid-template-areas: 'a';
        grid-auto-rows: min-content;
        grid-gap: 6px;
      }

      &-enter {
        opacity: 0;
      }

      &-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in-out;
      }

      &-exit {
        opacity: 1;
      }

      &-active {
        opacity: 0;
      }
    }
  }

  &__actions {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    padding: 0 24px;
    margin: 24px 0;
    background-color: #fff;
    z-index: 1;

    button {
      height: 36px;
      font-size: 14px;
      border-radius: 3px;
      padding: 0 16px;
    }

    @media screen and (max-width: 768px) {
      padding: 0 12px;
      margin: 12px 0;
    }

    @media screen and (max-width: 520px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: 0;
    padding: 24px;
  }
}
