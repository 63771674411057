@import 'styles/variables.less';

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1210;
  backdrop-filter: blur(4px);

  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100vw - 48px);
    max-height: calc(var(--vh, 1vh) * 100 - 48px);
    border-radius: 5px;
    margin: 0 12px;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px 12px 58px;
    background-color: #fafbfc;
    border-bottom: 1px solid #dce1e6;
    height: 60px;

    @media screen and (max-width: 768px) {
      padding: 12px 12px 12px 46px;
    }
  }

  &__icon {
    color: @primary-color;
  }

  &__title {
    margin: 0 auto;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 100%;
  }

  &__body {
    overflow-y: auto;
    display: flex;
    flex: auto;
    position: relative;
    z-index: 0;

    &-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      background-color: white;
      z-index: 10;
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 24px;

    &-error-msg {
      color: #d32f2f;
      font-size: 14px;
    }

    &-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      @media screen and (max-width: 520px) {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 24px 12px;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    padding: 24px 24px 0;
    text-align: center;
    word-break: break-word;
    white-space: pre-wrap;

    p {
      margin: 0;
    }
  }
}
