.menu {
  position: absolute;
  background-color: #fff;
  // border: 1px solid #eee;
  z-index: 100500;
  cursor: default;
  min-width: 200px;
  font-size: 14px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;

  &-inner {
    padding: 8px 0;
    max-height: 300px;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      height: 20px;
      border-radius: 1px;
      background-color: #d9d9d9;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a0;
    }
  }
  &-item-holder {
    display: flex;
    align-items: center;
    line-height: 1;
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding: 8px;

    .icon {
      margin-right: 8px;
    }
  }
  &-label {
    color: #777;
  }
  &-item {
    position: relative;
    cursor: pointer;
    color: #000;
    white-space: nowrap;

    &:hover > .menu-item-holder {
      background: #def0fe;
    }
    .rows {
      &:before,
      &:after {
        content: '';
        position: relative;
        border: 4px solid transparent;
        opacity: 0.5;
      }
      &:before {
        border-bottom-color: #3a3a3a;
        top: -12px;
        left: 7px;
      }
      &:after {
        border-top-color: #3a3a3a;
        top: 12px;
        left: -1px;
        opacity: 1;
      }
    }
    &-desc {
      .rows {
        &:before {
          opacity: 1;
        }
        &:after {
          opacity: 0.5;
        }
      }
    }
    &-disabled {
      color: #ccc;
      cursor: default;

      &:hover {
        background: none;
      }
    }

    &-danger {
      color: #fff;
      background: #ff4d4f;

      &:hover > .menu-item-holder {
        background: #ff7875;
      }
    }
  }
  &-sort {
    margin-top: 10px;
    margin-left: -285px;
    width: 280px;
    font-size: 12px;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      margin-left: 80px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 11px solid #fff;
    }
    .menu-inner {
      padding: 14px 0;
    }
    .menu-label,
    .menu-item {
      padding: 8px 30px;
    }
  }

  &.turn-left {
    transform: translateX(-50%);
    left: 50%;
  }

  &.turn-top {
    transform: translateY(-100%);
  }

  &.turn-top.turn-left {
    transform: translate(-50%, -100%);
  }

  &-export,
  &-create {
    min-width: 130px;
  }
}
