.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
