.lazy-image-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.lazy-image-item_thumb {
  filter: blur(20px);
  transform: scale(1);
}
