@import 'styles/variables.less';

.tab-control {
  background-color: white;
  display: flex;
  overflow-y: hidden;

  &._is-absolute {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__container-wrapper {
    flex: auto;
    display: flex;
    flex-direction: column;
    padding: @padding-sm;
    overflow-y: auto;

    &_small {
      padding: 0;
    }
  }

  &__container {
    flex: 3;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-template-rows: repeat(auto-fit, 1fr);
    grid-auto-rows: 1fr;
    gap: @gap-sm;

    &_small {
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }
  }

  &__item {
    padding: @padding-sm;
    cursor: pointer;
    border-radius: @border-radius-base;
    border: 2px dashed @border-color-secondary;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all @transition-duration-base @ease-in-out;

    &:hover {
      border: 2px solid @primary-color;
    }

    &_small {
      font-size: 14px;
    }

    &_disabled {
      user-select: none;
      pointer-events: none;
      border: 2px solid @border-color-secondary;
      background: @border-color-secondary;
    }

    &_screen {
      flex: 1;
      margin-bottom: 12px;
      border-color: @ant-warning-color;
    }
  }

  &__image {
    object-fit: cover;
    object-position: center;
    width: 48px;
    margin-bottom: @margin-sm;
  }
}
