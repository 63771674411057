@import 'styles/variables.less';

.room-materials {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 150px 40px;
    grid-template-areas: 'search select addbtn';
    gap: 6px;
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
      grid-template-columns: 150px 1fr 40px;
      grid-template-areas:
        'select . addbtn'
        'search search search';
    }
  }

  &__search {
    grid-area: search;
  }

  &__select {
    grid-area: select;
  }

  &__add-btn {
    grid-area: addbtn;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    scrollbar-color: #d9d9d9 #a0a0a0;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      height: 20px;
      border-radius: 1px;
      background-color: #d9d9d9;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a0;
    }
  }
}

.room-material-item {
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 12px;
  border-radius: 5px;
  transition: background-color 0.15s ease-in-out;
  overflow: hidden;

  &:hover {
    background-color: @background-color-base;
  }

  &:hover &__actions {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 4px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__image {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 12px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    overflow: hidden;

    &-subline {
      display: flex;
    }
  }

  &__name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__subtext {
    font-size: 12px;
    margin-right: 12px;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    opacity: 0;
    transition: all 0.15s ease-in-out;

    button {
      margin-right: 6px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
