.audio-player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;

  &-enter {
    opacity: 0;
  }

  &-active {
    transition: opacity 300ms;
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    transition: opacity 300ms;
    opacity: 0;
  }
}

.audio-player__track {
  flex-grow: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &-cover {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
  }

  &-info {
    margin: 0 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
    font-size: 14px;
    color: #fff;
  }

  &-title {
    line-height: 1;
  }

  &-date {
    line-height: 1;
    font-size: 12px;
  }
}

.audio-player__volume {
  position: relative;

  &-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    &-enter {
      opacity: 0;
      margin-bottom: -15px;
    }
    &-enter-active {
      transition: all 300ms;
      margin-bottom: 0;
      opacity: 1;
    }
    &-exit {
      opacity: 1;
      margin-bottom: 0;
    }
    &-exit-active {
      transition: all 300ms;
      margin-bottom: -15px;
      opacity: 0;
    }
  }

  &-holder {
    width: 48px;
    height: 65px;
  }

  &-inner {
    position: relative;
    padding: 24px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    width: 48px;
    height: 220px;
    box-sizing: border-box;
    border-radius: 25px;
  }
}
