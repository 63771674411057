.result {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 32px 12px;

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin: 24px 0 0;
  }

  &__subtitle {
    text-align: center;
    margin: 24px 0 0;
    font-size: 14px;
  }
}
