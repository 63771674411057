@import 'styles/variables.less';

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-position: top center;
  object-fit: cover;
  // box-shadow: @box-shadow-small;

  &__name {
    color: @white;
    text-align: center;
    font-weight: @font-weight-md;
    font-size: @font-size-base;
    line-height: 40px;
  }

  &_with-name {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_default {
    background-color: rgb(85, 85, 85);
  }
}
