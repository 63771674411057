@import 'styles/variables.less';

.dialog-image-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    z-index: 1;
    color: #ffffffd9;
    list-style: none;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: auto;
    line-height: 1;

    li {
      padding: 12px;
      margin-left: 12px;
      cursor: pointer;
      font-size: 20px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    overflow: hidden;
  }

  &__image {
    object-fit: contain;
    width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    user-select: none;
    pointer-events: auto;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: #ffffffd9;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;

    &_prev {
      left: 10px;
    }

    &_next {
      right: 10px;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
