.upload-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: 500;
}

.upload-inner-wrapper {
  width: 100%;
  max-width: 1440px;
  padding: 0 48px;
  margin: 0 auto;
  pointer-events: none;
}

.uploader {
  pointer-events: all;
  float: right;
  width: 100%;
  max-width: 320px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #91d5ff;
    padding: 12px 12px;
    color: #fff;
    font-size: 14px;
    position: relative;
    z-index: 0;
  }

  &__load {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    &-line {
      display: block;
      position: relative;
      height: 100%;
      -webkit-transform-origin: 0;
      background: #1b8ce3;
      transform-origin: 0;
      transition: all 0.4s linear 0s;
      overflow: hidden;
      z-index: 1;
    }
  }

  &__title {
    z-index: 2;
  }
}

.uploader__header-controlls {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  z-index: 2;
}

.uploader__switch {
  cursor: pointer;
}

.uploader__close {
  cursor: pointer;
}

.uploader__content {
  display: none;
  flex-direction: column;
  height: 240px;
  overflow: hidden;
}

.uploader__content_visible {
  display: flex;
}

.uploader__list {
  flex-grow: 1;
  padding-top: 8px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    height: 28px;
    border-radius: 1px;
    background-color: #a0a0a0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d9d9d9;
  }
}

@media screen and (max-width: 768px) {
  .upload-inner-wrapper {
    padding: 0 12px;
  }

  .uploader {
    max-width: 100%;
  }
}

.upload-wrapper-enter {
  opacity: 0;
  transform: translateY(50px);
}
.upload-wrapper-enter-active {
  transition: all 300ms;
  transform: translateY(0);
  opacity: 1;
}
.upload-wrapper-exit {
  opacity: 1;
  transform: translateY(0);
}
.upload-wrapper-exit-active {
  transition: all 300ms;
  transform: translateY(50px);
  opacity: 0;
}
